<template>
  <div v-if="dataclassAttrSytleEditDialog.status">
    <el-dialog
      title="样式管理"
      :visible.sync="dataclassAttrSytleEditDialog.status"
      width="22.39583vw"
      :class="
        dataclassAttrSytleEditDialog.text !== '地图数据样式编辑' ? 'dialog' : ''
      "
    >
      <el-form class="form" label-position="left" label-width="3.5vw">
        <el-form-item label="类型：">
          <el-radio-group v-model="dataSource" @change="getClassifyData">
            <el-radio-button
              v-for="item in dataSources"
              :key="item.id"
              :label="item.id"
              >{{ item.name }}</el-radio-button
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="分类：" v-show="dataSource !== 4">
          <el-select v-model="categoryId" @change="handleCategoryIdChange(1)">
            <el-option
              v-for="option in categoryIds"
              :key="option.id"
              :label="option.name"
              :value="option.id"
            />
          </el-select>
        </el-form-item>
        <!-- 图标 -->
        <div
          class="content"
          v-if="dataclassAttrSytleEditDialog.type === 'icon'"
          v-loading="loading"
        >
          <div
            class="content-icon"
            v-for="(item, index) in listData"
            :key="index"
            @click="handleClick(item)"
            @dblclick="handleDbClick(item)"
          >
            <div class="content-icon-img">
              <img :src="listData[index].url" />

              <i
                class="icon icon-list-checked1"
                v-show="item.id == styleData.id"
              ></i>
            </div>

            <el-tooltip
              :disabled="item.name.length < 4"
              :content="item.name"
              placement="right"
              effect="light"
            >
              <p class="content-icon-name">{{ item.name }}</p>
            </el-tooltip>
          </div>
          <div v-if="!listData[0] && !loading" class="emptyBox">
            <img
              v-if="network"
              src="~a/workbench/materiaLibrary/emptyNetwork.png"
            />
            <img v-else src="~a/workbench/materiaLibrary/emptyData.png" />
            <p class="emptyText">{{ network ? '网络异常' : '暂无素材' }}</p>
            <p class="emptyTextSearch" v-show="!network">
              请先前往“我的素材-图标库” 中上传样式~
            </p>
          </div>
        </div>
        <!-- 线段 -->
        <div
          class="content"
          v-if="dataclassAttrSytleEditDialog.type === 'line'"
          v-loading="loading"
        >
          <div
            class="content-line"
            v-for="(item, index) in listData"
            :key="index"
            @click="handleClick(item)"
            @dblclick="handleDbClick(item)"
          >
            <div class="content-line-header">
              <!-- 素材名称左侧 -->
              <el-tooltip
                :disabled="item.name.length < 21"
                :content="item.name"
                placement="right"
                effect="light"
              >
                <div class="content-line-header-left">
                  {{ item.name }}
                </div>
              </el-tooltip>
              <div class="content-line-header-right">
                <i
                  class="icon icon-list-checked1"
                  v-show="item.id == styleData.id"
                ></i>
              </div>
            </div>
            <!-- 样式预览 -->
            <div class="style-preview">
              <div
                class="style-preview-item"
                v-for="(color, index) in item.fillColor"
                :key="'1_' + index"
                :style="{ backgroundColor: color }"
              ></div>
            </div>
          </div>
          <div v-if="!listData[0] && !loading" class="emptyBox">
            <img
              v-if="network"
              src="~a/workbench/materiaLibrary/emptyNetwork.png"
            />
            <img v-else src="~a/workbench/materiaLibrary/emptyData.png" />
            <p class="emptyText">{{ network ? '网络异常' : '暂无素材' }}</p>
            <p class="emptyTextSearch" v-show="!network">
              请先前往“我的素材-线段库” 中上传样式~
            </p>
          </div>
        </div>
        <!-- 多边形 -->
        <div
          class="content"
          v-if="dataclassAttrSytleEditDialog.type === 'polygon'"
          v-loading="loading"
        >
          <div
            class="content-polygon"
            v-for="(item, index) in listData"
            :key="index"
            @click="handleClick(item)"
            @dblclick="handleDbClick(item)"
          >
            <div class="content-polygon-content">
              <div class="content-polygon-top">
                <div
                  class="item-width"
                  v-for="(color, index) in item.frameFillColor"
                  :key="'1_' + index"
                  :style="{ backgroundColor: color }"
                ></div>
              </div>
              <div class="content-polygon-box">
                <div class="content-polygon-left">
                  <div
                    v-show="index > 4"
                    class="item-height"
                    v-for="(color, index) in item.frameFillColor"
                    :key="'1_' + index"
                    :style="{ backgroundColor: color }"
                  ></div>
                </div>
                <!-- 填充内容 -->
                <div
                  class="content-polygon-color"
                  :style="{ background: item.fillColor }"
                >
                  <i
                    class="icon-list-checked1"
                    v-show="item.id == styleData.id"
                  ></i>
                </div>
                <div class="content-polygon-right">
                  <div
                    v-show="index < 5"
                    class="item-height"
                    v-for="(color, index) in item.frameFillColor"
                    :key="'1_' + index"
                    :style="{ backgroundColor: color }"
                  ></div>
                </div>
              </div>
              <div class="content-polygon-bottom">
                <div
                  class="item-width"
                  v-show="index > 4"
                  v-for="(color, index) in item.frameFillColor"
                  :key="'1_' + index"
                  :style="{ backgroundColor: color }"
                ></div>
                <div
                  class="item-width"
                  v-show="index < 5"
                  v-for="(color, index) in item.frameFillColor"
                  :key="'2_' + index"
                  :style="{ backgroundColor: color }"
                ></div>
              </div>
            </div>
            <el-tooltip
              :disabled="item.name.length < 9"
              :content="item.name"
              placement="right"
              effect="light"
            >
              <p class="content-polygon-name">{{ item.name }}</p>
            </el-tooltip>
          </div>
          <div v-if="!listData[0] && !loading" class="emptyBox">
            <img
              v-if="network"
              src="~a/workbench/materiaLibrary/emptyNetwork.png"
            />
            <img v-else src="~a/workbench/materiaLibrary/emptyData.png" />
            <p class="emptyText">{{ network ? '网络异常' : '暂无素材' }}</p>
            <p class="emptyTextSearch" v-show="!network">
              请先前往“我的素材-多边形库” 中上传样式~
            </p>
          </div>
        </div>
        <!-- 样式 -->
        <div
          class="content"
          v-if="dataclassAttrSytleEditDialog.type === 'resource'"
          v-loading="loading"
        >
          <div
            class="content-resource"
            v-for="(item, index) in listData"
            :key="index"
            @click="handleClick(item)"
            @dblclick="handleDbClick(item)"
          >
            <div class="content-resource-header">
              <!-- 素材名称左侧 -->
              <el-tooltip
                :disabled="item.name.length < 21"
                :content="item.name"
                placement="right"
                effect="light"
              >
                <div class="content-resource-header-left">
                  {{ item.name }}
                </div>
              </el-tooltip>
              <div class="content-resource-header-right">
                <i
                  class="icon icon-list-checked1"
                  v-show="item.id == styleData.id"
                ></i>
              </div>
            </div>
            <el-tooltip
              :content="item.description"
              placement="right"
              effect="light"
              :disabled="true"
            >
              <div class="content-resource-description">
                说明：{{ item.description }}
              </div>
            </el-tooltip>
          </div>
          <div v-if="!listData[0] && !loading" class="emptyBox">
            <img
              v-if="network"
              src="~a/workbench/materiaLibrary/emptyNetwork.png"
            />
            <img v-else src="~a/workbench/materiaLibrary/emptyData.png" />
            <p class="emptyText">{{ network ? '网络异常' : '暂无素材' }}</p>
            <p class="emptyTextSearch" v-show="!network">
              请先前往“我的素材-样式库” 中上传样式
            </p>
          </div>
        </div>
        <!-- 分页器 -->
        <div class="pagination" v-show="dataSource !== 4">
          <el-pagination
            layout="prev, pager, next"
            :page-size="paging.size"
            :total="paging.total"
            :current-page.sync="paging.page"
            @current-change="handleCurrentChange"
            hide-on-single-page
          >
          </el-pagination>
        </div>
        <!-- 底部按钮 -->
        <el-form-item>
          <div class="form-btn-group">
            <Button
              class="form-btn cancel"
              @click.stop="dataclassAttrSytleEditDialog = false"
              >取消</Button
            >
            <Button class="form-btn confirm" @click.stop="confirm">设置</Button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import $bus from 'p/utils';
import { ip } from 'p/urlSplice';
export default {
  name: 'dataclassAttrSytleEditDialog',
  data() {
    return {
      // 当前数据来源
      dataSource: 1,
      // 数据来源
      dataSources: [
        { name: '系统', id: 1 },
        { name: '我的', id: 2 },
        { name: '收藏', id: 3 },
        { name: '最近使用', id: 4 }
      ],
      // 分类id
      categoryId: '',
      categoryIds: [],
      // 当前点击的样式数据
      styleData: {},
      listData: [],
      // 分页数据
      paging: {
        size: 16,
        page: 1,
        total: 0
      },
      // 加载效果
      loading: true,
      // 网络状态 true为请求失败
      network: false
    };
  },
  computed: {
    dataclassAttrSytleEditDialog: {
      get() {
        return this.$store.state.data.dataclassAttrSytleEditDialog;
      },
      set(val) {
        this.$store.commit('data/setDataclassAttrSytleEditDialog', val);
      }
    }
  },
  methods: {
    // 获取分类数据
    getClassifyData() {
      this.loading = true;
      this.network = false;
      this.listData = [];
      this.paging.page = 1;
      this.paging.total = 0;

      // 判断type
      const params = this.estimateType();
      if (!params.lately) {
        this.$service.materialLibrary
          .styleResourceCategoryAll(params)
          .then(res => {
            if (res.data.status === 200) {
              this.categoryIds = res.data.data;
              this.categoryId = res.data.data[0].id;
              this.handleCategoryIdChange();
            }
          });
      } else {
        // 获取最近使用数据
        this.$service.materialLibrary[params.lately]()
          .then(res => {
            if (res.status === 200) {
              this.dataProcessing(res.data);
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
            this.network = true;
          });
      }
    },
    // 获取列表数据
    handleCategoryIdChange(val) {
      this.loading = true;
      this.network = false;
      this.listData = [];
      this.paging.total = 0;
      if (val) {
        this.paging.page = 1;
      }
      const type = this.estimateCategory();
      const params = {
        categoryId: this.categoryId,
        page: this.paging.page,
        system: this.dataSource == 1 ? 1 : 0
      };
      this.$service.materialLibrary[type](params)
        .then(res => {
          if (res.data.status === 200) {
            if (res.data.totalPage < res.data.page && res.data.totalPage != 0) {
              this.paging.page = 1;
              return this.handleCategoryIdChange();
            }
            this.dataProcessing(res.data);
            this.styleData = '';
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.network = true;
        });
    },
    // 列表数据处理
    dataProcessing(data) {
      let type = this.dataclassAttrSytleEditDialog.type;
      if (type == 'icon') {
        data.data.map(item => {
          item.url = ip.urlSplice(item.url);
        });
        this.listData = data.data;
      } else if (type == 'line') {
        this.listData = data.data;
        data.data.map((item, index) => {
          const fillColor = [];
          var num = 0;
          for (let i = 0; i < item.fill.length; i++) {
            const element = item.fill.charAt(i);
            if (element == 1) {
              fillColor.push(item.fillColor[num]);
              num = num + 1;
            } else {
              fillColor.push('#FFFFFF00');
            }
          }
          this.listData[index].fillColor = fillColor;
        });
      } else if (type == 'polygon') {
        this.listData = data.data;
        data.data.map((item, index) => {
          const frameFillColor = [];
          var num = 0;
          for (let i = 0; i < item.frameFill.length; i++) {
            const element = item.frameFill.charAt(i);
            if (element == 1) {
              frameFillColor.push(item.frameFillColor[num]);
              num = num + 1;
            } else {
              frameFillColor.push('#FFFFFF00');
            }
          }
          this.listData[index].frameFillColor = frameFillColor;
        });
      } else if (type == 'resource') {
        this.listData = data.data;
      }
      this.paging.page = data.page;
      this.paging.total = data.total;
    },
    // 单击获取当前点击的样式id
    handleClick(data) {
      this.styleData = data;
    },
    // 双击快速选择
    handleDbClick(data) {
      this.styleData = data;
      this.confirm();
    },
    // 设置提交
    confirm() {
      const styleData = this.styleData;
      const data = this.dataclassAttrSytleEditDialog;
      if (!styleData) return this.$message.warning('请选择要设置的样式');
      // 更新样式更改状态
      this.$store.commit('data/setDataNeedUpdate', [data.id]);
      // 返回数据到对应来源
      if (data.text == '要素数据类样式属性') {
        const params = {
          id: data.id,
          styleId: styleData.id
        };
        this.$service.data.featureClassAttrSet(params).then(res => {
          if (res.data.status === 200) {
            this.$message.success(res.data.msg);
            $bus.$emit('refreshAttr');
            this.$store.commit('data/setDataclassAttrSytleEditDialog', false);
          }
        });
      } else if (data.text == '地图数据样式编辑') {
        $bus.$emit('getStyleData', styleData);
        this.$store.commit('data/setDataclassAttrSytleEditDialog', false);
      } else if (data.text == '图层样式属性') {
        styleData.type = data.type;
        $bus.$emit('getLayerAttrStyleData', styleData);
        this.$store.commit('data/setDataclassAttrSytleEditDialog', false);
      }
    },
    // 判断请求分类的参数
    estimateType() {
      let params = {
        type: '',
        selectSystem: '',
        lately: ''
      };
      let text = this.dataclassAttrSytleEditDialog.type;
      if (text == 'icon') {
        switch (this.dataSource) {
          case 1:
            params = {
              type: '1',
              selectSystem: '1'
            };
            break;
          case 2:
            params = {
              type: '1',
              selectSystem: '0'
            };
            break;
          case 3:
            params = {
              type: '5',
              selectSystem: '0'
            };
            break;
          case 4:
            params = {
              lately: 'styleIconLately'
            };
            break;
        }
        return params;
      }
      if (text == 'line') {
        switch (this.dataSource) {
          case 1:
            params = {
              type: '2',
              selectSystem: '1'
            };
            break;
          case 2:
            params = {
              type: '2',
              selectSystem: '0'
            };
            break;
          case 3:
            params = {
              type: '6',
              selectSystem: '0'
            };
            break;
          case 4:
            params = {
              lately: 'styleIineLately'
            };
            break;
        }
        return params;
      }
      if (text == 'polygon') {
        switch (this.dataSource) {
          case 1:
            params = {
              type: '9',
              selectSystem: '1'
            };
            break;
          case 2:
            params = {
              type: '9',
              selectSystem: '0'
            };
            break;
          case 3:
            params = {
              type: '10',
              selectSystem: '0'
            };
            break;
          case 4:
            params = {
              lately: 'stylePolygonLately'
            };
            break;
        }
        return params;
      }
      if (text == 'resource') {
        switch (this.dataSource) {
          case 1:
            params = {
              type: '4',
              selectSystem: '1'
            };
            break;
          case 2:
            params = {
              type: '4',
              selectSystem: '0'
            };
            break;
          case 3:
            params = {
              type: '8',
              selectSystem: '0'
            };
            break;
          case 4:
            params = {
              lately: 'stylePolygonLately'
            };
            break;
        }
        return params;
      }
    },
    // 判断请求列表数据的方法
    estimateCategory() {
      let request = '';
      let text = this.dataclassAttrSytleEditDialog.type;
      if (text == 'icon') {
        request =
          this.dataSource == 3 ? 'styleIconCollectionList' : 'styleIconList';
      }
      if (text == 'line') {
        request =
          this.dataSource == 3 ? 'styleLineCollectionList' : 'styleLineList';
      }
      if (text == 'polygon') {
        request =
          this.dataSource == 3
            ? 'stylePolygonCollectionList'
            : 'stylePolygonList';
      }
      if (text == 'resource') {
        request =
          this.dataSource == 3
            ? 'styleResourceCollectionList'
            : 'styleResourceList';
      }
      return request;
    },
    // 分页器
    handleCurrentChange(val) {
      this.paging.page = val;
      this.handleCategoryIdChange();
    }
  },
  watch: {
    dataclassAttrSytleEditDialog: {
      immediate: true,
      handler(data) {
        if (data.status) {
          this.dataSource = 1;
          this.styleData = '';
          this.paging = {
            size: data.type == 'line' ? 15 : 16,
            page: 1,
            total: 0
          };
          this.dataSources =
            data.type == 'resource'
              ? [
                  { name: '系统', id: 1 },
                  { name: '我的', id: 2 },
                  { name: '收藏', id: 3 }
                ]
              : [
                  { name: '系统', id: 1 },
                  { name: '我的', id: 2 },
                  { name: '收藏', id: 3 },
                  { name: '最近使用', id: 4 }
                ];
          this.getClassifyData();
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.dialog {
  /deep/.el-dialog {
    margin-left: 1200px;
  }
}
.form {
  /deep/ .el-radio {
    &-group {
      display: flex;
      align-items: center;
      border-radius: 4px 0px 0px 4px;
    }
    &-button {
      flex: 1;
      height: 34px;
      &.is-active {
        .el-radio-button__inner {
          color: #fff;
          background-color: #507cff;
        }
      }
      &__inner {
        width: 100%;
        line-height: 34px;
        padding: 0;
        font-size: 14px;
        color: #666666;
      }
    }
  }

  .icon-wrap {
    margin-top: 20px;
  }
}

// 内容
.content {
  height: 330px;
  overflow: hidden;
  overflow-y: auto;
  margin-bottom: 10px;
  // 点图标
  &-icon {
    display: inline-block;
    margin: 0 16px;
    width: 54px;
    height: 78px;
    overflow: hidden;
    z-index: 1;
    &-img {
      width: 50px;
      height: 50px;
      display: flex;
      border: 2px solid #ffffff00;

      img {
        max-width: 50px;
        max-height: 50px;
        margin: auto;
        align-self: center;
      }
      .icon-list-checked1 {
        position: absolute;
        margin-left: 35px;
      }
    }

    &-name {
      width: 54px;
      text-align: center;
      font-size: 14px;
      color: #666666;
      text-overflow: ellipsis; /*显示省略符号来代表被修剪的文本*/
      overflow: hidden;
      white-space: nowrap; /*不换行 控制单行文本*/
    }
  }
  // 线段
  &-line {
    display: inline-block;
    margin-bottom: 10px;
    width: 348px;
    height: 56px;
    background: #f4f7ff;
    border: 1px solid #d3e1ff;
    border-radius: 6px;
    overflow: hidden;
    // 头部
    &-header {
      display: flex;
      justify-content: space-between;
      width: 348px;
      height: 20px;
      background: #e2eaff;
      // 左
      &-left {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin-left: 10px;
        width: 280px;
        text-overflow: ellipsis; /*显示省略符号来代表被修剪的文本*/
        overflow: hidden;
        white-space: nowrap; /*不换行 控制单行文本*/
      }
      // 右
      &-right {
        .icon {
          margin-top: 3px;
          margin-right: 10px;
        }
      }
    }
    // 样式预览
    .style-preview {
      display: flex;
      border-top: 1px solid #000;
      border-bottom: 1px solid #000;
      margin: 12px 10px;
      background: #fff;
      background-image: url(~a/workbench/materiaLibrary/block1-bg.png);
      &-item {
        flex: 1;
        height: 10px;
      }
    }
  }
  // 平多边形
  &-polygon {
    display: inline-block;
    margin: 0 11px 10px;

    &-content {
      width: 150px;
      height: 80px;
      overflow: hidden;
      background-image: url(~a/workbench/materiaLibrary/block1-bg.png);
    }
    .item-width {
      display: flex;
      width: 15px;
      height: 8px;
    }
    .item-height {
      display: flex;
      width: 8px;
      height: 15px;
    }
    &-top {
      display: flex;
    }
    &-right {
      display: flex;
      flex-direction: column;
    }
    &-bottom {
      display: flex;
      flex-direction: row-reverse;
    }
    &-left {
      display: flex;
      flex-direction: column-reverse;
    }
    &-box {
      height: 65px;
      display: flex;
      justify-content: space-between;
    }
    &-color {
      width: 134px;
      height: 65px;
      z-index: 0;
      .icon-list-checked1 {
        margin-left: 120px;
        display: flex;
        z-index: 1;
      }
    }

    &-name {
      width: 150px;
      text-align: center;
      font-size: 14px;
      color: #666666;
      margin-top: 2px;
      text-overflow: ellipsis; /*显示省略符号来代表被修剪的文本*/
      overflow: hidden;
      white-space: nowrap; /*不换行 控制单行文本*/
    }
  }
  // 样式
  &-resource {
    display: inline-block;
    margin-bottom: 10px;
    width: 348px;
    height: 56px;
    background: #f4f7ff;
    border: 1px solid #d3e1ff;
    border-radius: 6px;
    overflow: hidden;
    // 头部
    &-header {
      display: flex;
      justify-content: space-between;
      width: 348px;
      height: 20px;
      background: #e2eaff;
      // 左
      &-left {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin-left: 10px;
        width: 300px;
        text-overflow: ellipsis; /*显示省略符号来代表被修剪的文本*/
        overflow: hidden;
        white-space: nowrap; /*不换行 控制单行文本*/
      }
      // 右
      &-right {
        .icon {
          margin-top: 3px;
          margin-right: 10px;
        }
      }
    }
    // 说明
    &-description {
      margin-left: 10px;
      width: 330px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 35px;
      text-overflow: ellipsis; /*显示省略符号来代表被修剪的文本*/
      overflow: hidden;
      white-space: nowrap; /*不换行 控制单行文本*/
    }
  }
}

// 空状态
.emptyBox {
  height: calc(100vh - 641px);
  overflow: hidden;
  overflow-y: auto;
  img {
    display: flex;
    margin: 40px auto 0;
    width: 212px;
    height: 130px;
  }
  .emptyText {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    line-height: 16px;
    font-size: 16px;
    text-align: center;
    margin: 40px auto 10px;
  }
  .emptyTextSearch {
    text-align: center;
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1b1b1b;
    line-height: 16px;
    opacity: 0.5;
  }
}
// 分页器
.pagination {
  width: 100%;
  height: 20px;
  display: flex;
  /deep/ .el-pagination {
    padding: 0;
    margin: 0 auto;
    .el-pager li {
      height: 20px;
      line-height: 21px;
      min-width: 2px;
      font-size: 14px;
      font-weight: 400;
      background: transparent;
    }
    .btn-next,
    .btn-prev {
      background: transparent;
      min-width: 10px;
      width: 10px;
      height: 22px;
      padding: 0;
    }
    .btn-prev {
      margin-right: 5px;
    }
    .el-icon-arrow-right:before,
    .el-icon-arrow-left:before {
      font-size: 14px;
    }
  }
}
</style>
