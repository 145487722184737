export const ip = {
  urlSplice(url) {
    const losIp = window.losIp;
    let newUrl = losIp + url;
    if (url.indexOf('http') !== -1 || url.indexOf(losIp) !== -1) {
      return url;
    } else {
      return newUrl;
    }
  }
};
